.shopsofasizesection {
    padding: 25px 10px 5px;
    overflow: hidden;
    border-bottom: 1px solid #E7E7E7;

    .shopsofasizeheading {
        margin-bottom: 15px;
        text-align: center;

        .head-title,
        .title {
            font-size: 20px;
            line-height: 20px;
            color: #333333;
            font-weight: 500;
            font-family: 'pangrammedium';
            margin: 0px 0px 2px;
            letter-spacing: 0.5px;
        }

        .sub-title {
            font-size: 13px;
            line-height: 20px;
            color: #646464;
            font-weight: 400;
            font-family: "pangramregular";
            margin: 0px;
            letter-spacing: 0.5px;
        }
    }

    .shopsofasizecontant {
        overflow: auto;
        white-space: nowrap;
        margin-right: -10px;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }

    .shopsofasizecard {

        figure {
            position: relative;
            overflow: hidden;
            background: #f5f5f5;

            &:before {
                padding-top: 86%;
                content: "";
                display: block;
            }

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 100%;
                height: auto;
                transform: scale(1);
                transition: 0.3s all ease-in-out;
                display: block;
            }
        }

        .price {
            font-size: 13px;
            line-height: 18px;
            color: #646464;
            letter-spacing: 0.02em;
            margin: 0px;
            text-align: left;
            font-family: "pangramregular";

            .offprice {
                margin: 0 3px;
            }

            .discountoff {
                color: forestgreen;
            }
        }

        .col-name {
            font-family: "pangrammedium";
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover {
            figure {
                img {
                    transform: scale(1.04);
                }

                .col-name {
                    color: #e57200;
                }

            }
        }
    }
}

@media (max-width: 767px) {

    .grid-container-4-col {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-auto-flow: column;
        grid-auto-columns: minmax(230px, 1fr);
        gap: 20px 10px;
        overflow: auto !important;
        margin-bottom: 10px;

        &::-webkit-scrollbar {
            display: none;
        }

        .shopsofasizecard {
            figure {
                margin-bottom: 10px;
            }
        }
    }
}



.viewmorebtndiv {
    text-align: center;
    margin-top: 15px;

    .view-all-furni {
        padding: 8px 20px;
        font-size: 14px;
        line-height: 18px;
        background: transparent;
        color: #515151;
        border: 1px solid #8b8b8b;
        border-radius: 4px;
        font-family: "pangrammedium";
        text-transform: capitalize;
    }
}