// storesection
.seasonsection {
    margin: 20px 0px;
    .seasonimg {
        a {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

@media (max-width:767px) {
  .seasonsection {
    margin: 10px 0px;

    .seasonimg {

      a {
        display: block;
      }
      img { 
        width: 100%;
        height: auto;
      }
    }    
  }  
}