.shopsofasizesection {
    padding: 50px 0px;



    .shopsofasizeheading {
        margin-bottom: 20px;
        text-align: center;


        .title {
            font-size: 28px;
            line-height: 34px;
            color: #333333;
            letter-spacing: 0.02em;
            font-family: "pangrammedium";
            text-transform: capitalize;
        }

        .sub-title {
            font-size: 15px;
            line-height: 20px;
            font-family: "pangramregular";
            color: #515151;
            font-weight: 400;
            letter-spacing: 0.5px;
            margin: 0px;
        }
    }

    .modfurniturbottom {
        text-align: center;

        .btn-primary {
            text-align: center;
            background: var(--bg-orange-light);
            border: 1px solid var(--bg-orange-light);
            padding: 10px 35px;
            margin: 40px auto 0;
            border-radius: 3px;
            color: #fff;
            font-size: 17px;
            transition: 0.3s ease-in-out;
            border: 1px solid transparent;
            display: block;
            font-family: "pangramregular";
            display: inline-flex;
            align-items: center;

            img {
                width: 18px;
                height: 18px;
                filter: brightness(10);
            }

            &:hover {
                color: var(--bg-orange-light);
                background-color: #fff;
                border-color: var(--bg-orange-light);
            }

        }
    }

    .shopsofasizecard {
        display: block;
        margin-bottom: 0px;


        figure {
            position: relative;
            overflow: hidden;
            border-radius: 3px;

            &:before {
                content: "";
                padding-top: 86%;
                display: block;
            }

            img {
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                transition: 0.3s all ease-in-out;
                transform: scale(1);
                margin: auto;
                width: auto;
                height: auto;
                border-radius: 3px;
            }
        }

        .col-name {
            font-family: "pangrammedium";
            font-size: 15px;
            line-height: 20px;
            color: #333333;
            letter-spacing: 0.02em;
            margin: 5px 0px 3px;
            display: block;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .price {
            font-size: 15px;
            line-height: 21px;
            color: #333;
            text-align: left;
            letter-spacing: 0.02em;
            font-family: "pangramregular";

            .offprice {
                font-family: "pangramregular";
                color: #646464;
                font-size: 14px;
                margin: 0 4px;
            }

            .discountoff {
                color: #4CAF4F;
                display: inline-block;
                font-size: 14px;
            }
        }

        .sale {
            position: absolute;
            left: 0px;
            top: 0px;
            background: #E27A34;
            padding: 0px 10px 0;
            color: #fff;
            font-size: 15px;
            line-height: 28px;
            border-radius: 4px;
            text-transform: capitalize;
        }

        &:hover {
            img {
                transform: scale(1.04);
            }
        }
    }
}