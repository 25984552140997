.finestbrandsection {
    padding: 50px 0px 20px;

    .finestbrandheading {
        text-align: center;
        margin-bottom: 40px;

        h2 {
            font-size: 38px;
            color: #333333;
            font-family: "Abhay-Libre Bold";
            text-transform: capitalize;
            margin: 0px;
            letter-spacing: 0.02em;
        }

        .title {
            font-size: 38px;
            color: #333333;
            font-family: "Abhay-Libre Bold";
            text-transform: capitalize;
            margin: 0px;
            letter-spacing: 0.02em;

            h1 {
                font-size: 38px;
                display: inline;
                margin: 0;
            }
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-family: "pangramregular";
            font-weight: normal;
            color: #646464;
            letter-spacing: 0.03em;
            position: relative;
            overflow: hidden;
            width: 70%;
            margin: auto;
            width: 70%;

            a {
                color: #E27A34;

                &:hover {
                    text-decoration: underline;
                }
            }

            div {
                margin: 0px;
            }

        }

        .readmore {
            color: #E27A34;
            font-size: 14px;
            position: absolute;
            right: 0px;
            z-index: 1;
            top: 0px;
            background-color: #fff;
            padding: 1px 0px;
            cursor: pointer;
        }
    }

    .finestbrandcard {
        display: block;
        margin: 0px 4px 30px;
        overflow: hidden;
        border-radius: 4px;

        .finestbrandimg {
            figure {
                margin: auto;
                display: block;
                position: relative;
                overflow: hidden;
                border-radius: 4px;
                background: #f5f5f5;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 86% !important;
                }
            }

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                width: auto;
                height: auto;
                transform: scale(1);
                transition: 0.3s ease-in-out;
            }
        }

        &:hover {
            .finestbrandimg {
                img {
                    transform: scale(1.04);
                }
            }

        }
    }
}

// storesection
.seasonsection {
    margin: 20px 0px;

    .seasoninner {
        padding: 0px 2px;
        margin: 0px auto 0px;
    }

    .seasonimg {
        a {
            display: inline-block;
            width: 100%;
            text-align: center;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

@media (min-width:1921px) {
    .seasonsection {
        .seasonimg {
            img {
                width: auto;
            }
        }
    }
}

@media (max-width:1280px) {
    .finestbrandsection {
        padding: 30px 0px 0px;

        .finestbrandheading {
            h2 {
                font-size: 32px;
                line-height: 36px;
            }

            .title {
                font-size: 32px;
                line-height: 36px;

                h1 {
                    font-size: 32px;
                    display: inline;
                    margin: 0;
                }
            }
        }

        .finestbrandcard {
            margin: 0px 0px 30px;
        }
    }

    .seasonsection {
        margin: 0px 0px 10px;
    }
}

@media (max-width:991px) {
    .finestbrandsection {
        .finestbrandheading {
            p {
                max-width: 458px;
            }
        }
    }
}

@media (max-width:767px) {
    .finestbrandsection {
        padding: 50px 0px;

        .finestbrandheading {
            margin: 0 0 20px;

            .title {
                font-size: 28px;
                line-height: 32px;
                font-family: 'Abhay-Libre Extra Bold';

                h1 {
                    font-size: 30px;
                    display: inline;
                    margin: 0;
                }
            }

            p {
                font-size: 14px;
                line-height: 20px;
            }
        }

    }

    .seasonsection {
        .seasonimg {

            img {
                object-fit: contain;
                height: auto;
            }
        }
    }
}

@media (max-width:575px) {
    .finestbrandsection {
        padding: 40px 0px 20px;
    }
}