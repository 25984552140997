.store-text-section {
    padding: 40px 0px;
    border-top: 1px solid #e5e5e5;

    h2 {
        padding: 10px 0px 5px;
        margin: 0;
        letter-spacing: 0.015em;
        color: #333333;
        font-family: "pangrammedium";
        font-size: 1.1875rem;
        line-height: 23px;
    }

    h3 {
        color: #333333;
        font-size: 1.0625rem;
        line-height: 21px;
        font-weight: 500;
        padding: 10px 0px 5px;
        margin: 0;
        letter-spacing: 0.015em;
        font-family: "pangrammedium";

    }

    p {
        font-size: 0.9375rem;
        line-height: 22px;
        margin: 0 0 10px;
        color: #515151;
        text-align: justify;
        font-family: "pangramregular";
        letter-spacing: 0.5px;

        strong {
            color: #333333;
            font-family: "pangrammedium";
        }
    }

    a {
        text-decoration: none;
        color: #e57200;
    }

    ol {
        li {
            strong {
                color: #333333;
                font-family: "pangrammedium";
            }

            font-size: 0.9375rem;
            line-height: 22px;
            margin: 0 0 10px;
            color: #515151;
            font-weight: 400;
            text-align: justify;
            font-family: "pangramregular";
            letter-spacing: 0.5px;

        }
    }

    ul li {
        font-size: 0.9375rem;
        line-height: 22px;
        margin: 0 0 10px;
        color: #515151;
        text-align: justify;
        font-family: "pangramregular";
        letter-spacing: 0.5px;
    }

}

@media (max-width:767px) {
    .store-text-section {
        padding: 20px 10px;

        h2 {
            padding: 5px 0px 5px;
            letter-spacing: 0.03em;
            color: #333333;
            font-family: "pangrammedium";
            font-size: 1rem;
            line-height: 21px;
        }

        h3 {
            font-size: 1rem;
            line-height: 21px;
            font-weight: normal;
            letter-spacing: 0.03em;
            padding: 5px 0px 5px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            color: #515151;
            letter-spacing: 0.2px;

            strong {
                color: #333333;
                font-family: "pangrammedium";
            }
        }

        a {
            text-decoration: none;
            color: #e57200;
        }

        ol {
            li {
                strong {
                    color: #333333;
                    font-family: "pangrammedium";
                }

                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;

            }
        }
    }
}