.sofasetsection {

    .title {
        font-size: 28px;
        line-height: 32px;
        color: #333333;
        font-family: 'pangrammedium';
        text-align: center;
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        font-family: pangramregular;
        color: #515151;
        font-weight: 400;
        letter-spacing: .02em;
        position: relative;
        min-height: 20px;
        height: 100%;
        margin: 0px auto;
        max-width: 650px;

        b {
            font-weight: 500;
            color: #333;
            font-size: 15px;
        }

        .readmore {
            color: #e57200;
            font-size: 14px;
            position: absolute;
            right: 0px;
            z-index: 1;
            top: 0px;
            background-color: #fff;
            padding: 1px 4px;
            cursor: pointer;
        }
    }

    .sofasetcontent {
        margin-bottom: 20px;
        border-bottom: 1px solid #e7e7e7;
    }

    .sofasetcard {
        display: block;
        margin-bottom: 20px;
        text-align: center;
        padding: 0 10px;

        figure {
            position: relative;
            overflow: hidden;
            margin-bottom: 10px;

            &:before {
                content: '';
                padding-top: 95%;
                display: block;
            }

            img {
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                width: auto;
                height: auto;
            }
        }


        .category-name {
            font-size: 15px;
            line-height: 20px;
            color: #333;
            font-family: 'pangramregular';
            padding: 5px 0 10px;
            letter-spacing: 0.02em;
        }

        &:hover {
            .category-name {
                color: #e57200;
            }
        }
    }
}

@media (max-width: 768px) {
    .sofasetsection {

        .sofaset-heading {
            text-align: center;
            margin-bottom: 16px;
            .title {
                font-size: 1.25rem;
                line-height: 12px;
                font-family: "Abhay-Libre Extra Bold";
            }
        }

        .category-card {
            width: 33% !important;
            
            .img-card{
                border-radius: 4px;
            }
        }

        .sofasetcard {


            padding: 0 3px !important;

            figure {
                &:before {
                    padding-top: 86%;
                }
            }

            .sofasettext {

                p {
                    font-size: 13px;
                    line-height: 16px;
                    margin: 0;
                    padding: 0;
                    max-width: 100%;
                    min-height: auto;
                }
            }
        }
    }
}