.populrsofasection {
    padding: 30px 0px 0px;

    .populrsofainner {
        max-width: 96%;
        margin: auto;
        padding-bottom: 30px;
        border-bottom: 2px solid #f5f5f5;
    }

    .viewsofabutton {
        text-align: center;
        margin-top: 20px;
    }

    .tabstop {
        margin-bottom: 20px;
    }

    .populrsofaheading {
        margin-bottom: 30px;
        text-align: center;

        .title {
            font-size: 28px;
            line-height: 32px;
            color: #333333;
            font-family: "pangrammedium";
            margin: 0 0 5px;
            letter-spacing: 0.02em;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            font-family: "pangramregular";
            color: #646464;
            letter-spacing: 0.03em;
            margin: 0px;
        }

    }

    .populrsofacard {
        display: block;
        overflow: hidden;
        margin-bottom: 20px;

        .populrsofaimg {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                border-radius: 3px;
                transition: all .3s ease;
            }
        }

        .populrsofatext {
            padding: 6px 0px;

            p {
                font-size: 15px;
                line-height: 21px;
                color: #333333;
                letter-spacing: 0.02em;
                font-weight: 300;
                margin: 0 0 3px;
                font-family: "pangramregular";
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            h4 {
                font-family: "pangrammedium";
                font-size: 15px;
                line-height: 18px;
                color: #333333;
                font-weight: 400;
                letter-spacing: 0.02em;
                margin: 0px 0px 6px;
            }

            .offprice {
                font-family: "pangramregular";
                color: #646464;
                font-size: 14px;
                margin: 0 4px;
            }

            .discountoff {
                color: #4CAF4F;
                display: inline-block;
                font-size: 14px;
            }

        }
    }
}

@media screen and (max-width:767px) {
    .populrsofasection {
        .populrsofaheading {
            .title {
                font-size: 20px;
                line-height: 20px;
                color: #333333;
                font-weight: 500;
            }

            p {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}


@media (max-width:767px) {
    .populrsofasection {
        padding: 20px 10px;
        overflow: hidden;
        border-top: 1px solid #e5e5e5;



        .row {
            overflow: auto;
            white-space: nowrap;
            margin-right: -10px;

            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
                display: none;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
            }

            .col {
                padding: 0px;
                margin-right: 10px;
                width: 46%;
                display: inline-grid;

            }


        }

        .essentfturcard {
            margin-bottom: 10px;
            display: inline-block;
            width: 100%;


            figure {
                border-radius: 3px;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .essentfturtext {
                padding: 5px 0px 5px;

                .productname {
                    font-size: 0.8125rem;
                    line-height: 16px;
                }

                .price {
                    font-family: "pangramregular";
                    font-size: 0.75rem;
                    color: #646464;
                }
            }
        }

        .tabs-nav {
            padding: 0px 0 10px;

            .tabs-item {
                display: inline-block;

                .tabs-btn {
                    font-size: 0.8125rem;
                    line-height: 17px;
                    font-family: "pangramregular";
                    border: 1px solid #d1d1d1;
                    padding: 5px 12px;
                }
            }
        }
    }

}